import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom';
import { signIn, signUp } from "../../service/user";
import Success from "../component/success";
import RecaptchaItem from "./recaptchaItem";
import './index.less';

const SignUp = () => {
    // 注册成功
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [signUpError, setSignUpError] = useState("");
    // TODO 登录 跳转 输入错误报错
    const onFinish = async (values) => {
        console.log('Received values of form: ', values);
        // 注册
        // 登录
        try {
            const res =  await signUp(values);
            if (res.status === 200) {
                setSuccess(true);
            }else if(res.status === 201) {
                setSignUpError(res.data.message);
            }
            //await signIn(values);
            
        } catch (error) {
            message.error('出错了')
            // 邮箱重复？密码不正确？
        }

    };

    return success ?
        <Success text={intl.get('signInLayout.signUp.signUpSuccessText')} />
        :
        <div className='signUp'>
            <div className='title'>{intl.get('signInLayout.signUp.title')}</div>
            <Form
                name="signUp"
                onFinish={onFinish}
                scrollToFirstError
            >
                <p className='error-message'>{signUpError}</p>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: intl.get('signInLayout.signUp.EmailInvalid'),
                        },
                        {
                            required: true,
                            message: intl.get('signInLayout.signUp.EmailEmpty'),
                        },
                    ]}
                    validateTrigger={['onBlur']}
                >
                    <Input className="input" placeholder={intl.get('signInLayout.signUp.EmailPlaceholder')} />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{6,99999}$/,
                            message: intl.get('signInLayout.signUp.passwordInvalid'),
                        },
                        {
                            required: true,
                            message: intl.get('signInLayout.signUp.passwordEmpty'),
                        },
                    ]}
                    validateTrigger={['onBlur']}
                >
                    <Input.Password className="input" placeholder={intl.get('signInLayout.signUp.passwordPlaceholder')} />
                </Form.Item>

                <Form.Item name="g-recaptcha-response"
                    rules={[
                        {
                            required: true,
                            message: intl.get('signInLayout.signUp.recaptchaEmpty'),
                        },
                    ]}
                >
                    <RecaptchaItem />
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit" className='sign-up-btn'>
                        {intl.get('signInLayout.signIn.signUpBtn')}
                    </Button>
                </Form.Item>
            </Form>

            <div className='title-sign-in'>{intl.get('signInLayout.signUp.titleSignIn')}</div>

            <Button className='sign-in-btn' onClick={() => navigate('/signin')}>
                {intl.get('signInLayout.signIn.signInBtn')}
            </Button>
        </div>
}

export default SignUp